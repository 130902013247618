import React from "react"
import { graphql } from "gatsby"

import Header from "../components/Header"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

class ProjectsTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    console.log(post)

    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Bio />
        <Header />

        <div className="mt-16 md:mt-28">
          <h2 className="">{post.frontmatter.title}</h2>
          <div className="flex items-center gap-4 mb-4">
            {post.frontmatter.github ? (
              <a
                href={post.frontmatter.github}
                className="no-underline shadow-none flex items-center"
              >
                GitHub link:
                <img
                  width="24"
                  className="ml-2"
                  src="https://res.cloudinary.com/jaygould/image/upload/v1660426732/blogs/gtihub-icon.svg"
                />
              </a>
            ) : null}
            {post.frontmatter.website ? (
              <a
                href={post.frontmatter.website}
                className="no-underline shadow-none flex items-center"
              >
                Website link:
                <img
                  width="24"
                  className="ml-2"
                  src="https://res.cloudinary.com/jaygould/image/upload/v1660427657/blogs/website-icon.svg"
                />
              </a>
            ) : null}
            {post.frontmatter.relatedBlog ? (
              <a
                href={post.frontmatter.relatedBlog}
                className="no-underline shadow-none flex items-center"
              >
                Related blog link:
                <img
                  width="24"
                  className="ml-2"
                  src="https://res.cloudinary.com/jaygould/image/upload/v1660490182/blogs/blog-post-icon.svg"
                />
              </a>
            ) : null}
          </div>

          <blockquote>{post.frontmatter.description}</blockquote>

          <div className="" dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
        </div>
      </Layout>
    )
  }
}

export default ProjectsTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      excerpt
      fields {
        slug
      }
      html
      frontmatter {
        title
        description
        tech
        image
        github
        website
        relatedBlog
      }
    }
  }
`
